import axios from '../axiosApi'

const initialState = {
  data: [],
  item: {},
  processedImage: {},
  idDelete: '',
  error: '',
  newItem: '',
  count: 0,
  changeItem: '',
  showFilters: false,
  dataHerdarium: {},
  loading: false
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setLoading(state) {
      state.loading = !state.loading
    },
    getData(state, data) {
      state.data = data.result
      state.count = data.count
    },
    getItem(state, item) {
      state.item = item
    },
    fetchDelete(state, idDelete) {
      state.idDelete = idDelete
    },
    errorInfo(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = ''
    },
    addItem(state, newItem) {
      state.newItem = newItem
    },
    changeItem(state, changeItem) {
      state.changeItem = changeItem
    },
    clearItem(state) {
      state.item = ''
    },
    setFilters(state) {
      state.showFilters = !state.showFilters
    },
    clearStateHerbarium(state) {
      Object.assign(state, initialState)
    },
    processedImage(state, images) {
      state.processedImage = images
    },
    getDataHerbarium(state, data) {
      state.dataHerdarium = data
    }
  },
  actions: {
    fetchData({ commit }, options) {
      commit('setLoading')
      const limit = options.limit || 5
      const offset = options.offset || 1
      return axios
        .get(`/herbarium?limit=${limit}&offset=${offset}`)
        .then(({ data }) => {
          commit('getData', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    fetchItem({ commit }, id) {
      commit('clearStateHerbarium')
      commit('setLoading')
      return axios
        .get(`/herbarium/${id}`)
        .then(data => {
          commit('getItem', data.data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    deleteItem({ commit }, id) {
      commit('setLoading')
      return axios
        .delete(`/herbarium/${id}`)
        .then(() => {
          commit('fetchDelete', id)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    addItemToDB({ commit, dispatch }, item) {
      commit('setLoading')
      commit('clearError')
      return axios
        .post('/herbarium/', item)
        .then(newItem => {
          commit('addItem', newItem.data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
          commit('setLoading')
        })
    },
    changeItemToDB({ commit, dispatch }, data) {
      commit('setLoading')
      commit('clearError')

      return axios
        .put(`/herbarium/${data.id}`, data.form)
        .then(changeItem => {
          commit('changeItem', changeItem.data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
          commit('setLoading')
        })
    },
    clearErrorAction({ commit }) {
      commit('clearError')
    },
    fetchTags({ commit }, name) {
      commit('setLoading')
      return axios
        .get(`/tag/show?name=${name}`)
        .then(({ data }) => {
          commit('getData', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    clearItemInfo({ commit }) {
      commit('clearItem')
    },
    setShowFilters({ commit }) {
      commit('setFilters')
    },
    getImageProcessed({ commit, dispatch }, options) {
      commit('setLoading')
      return axios
        .get(
          `/herbarium/processed?page=${options.page}&processed=${options.processed}`
        )
        .then(({ data }) => {
          commit('processedImage', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
          commit('setLoading')
        })
    },
    fetchDataFilter({ commit, dispatch }, url) {
      commit('setLoading')
      return axios
        .get(url)
        .then(({ data }) => {
          commit('getData', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
          commit('setLoading')
        })
    }
  },
  getters: {
    getData(state) {
      return state.data
    },
    getItem(state) {
      return state.item
    },
    getErrorHerbarium(state) {
      return state.error
    },
    getShowFilters(state) {
      return state.showFilters
    },
    isLoading(state) {
      return state.loading
    },
    imageProcessed(state) {
      return state.processedImage.rows
    },
    imageProcessedCount(state) {
      return state.processedImage.count
    },
    getCount(state) {
      return state.count
    },
    getDataHerbarium(state) {
      return state.dataHerdarium
    }
  }
}
