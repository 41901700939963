<template>
  <div id="app">
    <router-view />
    <Toast />
  </div>
</template>

<script>
import Toast from '@/components/Toast.vue'

export default {
  name: 'MainPageFooter',
  components: {
    Toast
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
  margin: 0;
}

#app {
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
