<template>
  <b-toast
    variant="default"
    solid
    :visible="dataToast.show"
    :auto-hide-delay="4000"
    :append-toast="true"
    @hidden="hideToast"
  >
    <div class="d-flex p-2 toast-disign align-items-start">
      <img
        :src="require(`../assets/icon/icon-${dataToast.type}.svg`)"
        alt="icon-status"
        class="mx-1"
      />
      <span class="toast-message flex-grow-1 mt-1 ml-2 text-break">
        {{ this.$t(dataToast.message) }}
      </span>
      <button class="btn btn-link pt-0" @click="hideToast">
        <img src="../assets/icon/icon-dismiss.svg" alt="dismiss-icon" />
      </button>
    </div>
  </b-toast>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: mapGetters(['dataToast']),
  methods: mapActions(['hideToast'])
}
</script>

<style lang="scss">
.toast-header {
  display: none !important;
  border: none !important;
}
.toast {
  background-clip: initial !important;
}
.toast-body {
  padding: 0 !important;
}

.toast-message {
  line-height: 1.2;
}
</style>
