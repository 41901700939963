import axios from '../axiosApi'

const initialState = {
  genus: []
}

export default {
  state: { ...initialState },
  mutations: {
    setDataGenus(state, data) {
      state.genus = data
    }
  },
  actions: {
    requestDataGenusName({ commit, dispatch }, value) {
      axios(`/classification/genus?search=${value}`)
        .then(res => res.data.map(el => ({ id: el.id, name: el.laName })))
        .then(res => commit('setDataGenus', res))
        .catch(({ data }) => {
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        })
    },
    requestDataGenus({ commit, dispatch }, arr) {
      const arrFormat = JSON.stringify(arr.map(el => el.id))
      axios
        .get(`/classification/genus?family=${arrFormat}`)
        .then(res => res.data.map(el => ({ id: el.id, name: el.laName })))
        .then(res => commit('setDataGenus', res))
        .catch(({ data }) => {
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        })
    }
  },
  getters: {
    getGenus(state) {
      return state.genus
    }
  }
}
