<template>
  <div class="map-wrap">
    <LMap
      ref="mymap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      :style="readOnly ? 'height:100%' : 'height: 87%'"
      @click="addMarker"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <LTileLayer :url="url" />
      <LMarker
        v-for="marker in markers"
        :key="marker.id"
        :lat-lng="marker.latlng"
        :icon="marker.id === itemId ? iconItem : iconDefault"
        @click="removeMarker()"
      >
        <LPopup>
          <div style="cursor:pointer" @click="innerClick(marker.id)">
            {{ languageTake === 'ru' ? marker.ruName : marker.enName }}
          </div>
        </LPopup>
      </LMarker>
    </LMap>
    <div v-if="readOnly" class="information">
      <div v-for="marker in markers" :key="marker.id">
        <p v-if="marker.id === itemId">
          <strong>
            {{ $t('herbarium_form_page_input_latitude_placeholder') }} :
          </strong>
          <span>
            {{ marker.latlng.lat && marker.latlng.lat.toFixed(6) }}
          </span>
        </p>
        <p v-if="marker.id === itemId">
          <strong>
            {{ $t('herbarium_form_page_input_longitude_placeholder') }} :
          </strong>
          <span>
            {{ marker.latlng.lng && marker.latlng.lng.toFixed(6) }}
          </span>
        </p>
      </div>
    </div>
    <div v-if="!readOnly" class="d-flex justify-content-between mt-2">
      <div class="col-5 d-flex">
        <b-form-group
          id="lat"
          :label="$t('herbarium_form_page_input_latitude_placeholder')"
          label-for="lat-input"
          class="mr-2"
        >
          <b-form-input id="lat-input" v-model="lngLat.lat" type="text" />
        </b-form-group>

        <b-form-group
          id="lng"
          :label="$t('herbarium_form_page_input_longitude_placeholder')"
          label-for="lng-input"
        >
          <b-form-input id="lng-input" v-model="lngLat.lng" type="text" />
        </b-form-group>
      </div>
      <b-button
        type="button"
        class="success align-self-center"
        @click="saveCoord"
      >
        {{ $t('main_page_sub_nav_save_result') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import L from 'leaflet'

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  props: {
    itemId: { type: Number, default: () => null },
    coordinates: { type: Object, default: () => {} },
    readOnly: { type: Boolean, default: () => false },
    specicesId: { type: Number, default: () => null },
    coordAllHerbarium: { type: Array, default: () => [] }
  },
  data() {
    return {
      lngLat: {
        lat: 0,
        lng: 0
      },
      iconItem: icon({
        iconUrl: require(`../assets/icon/icon-marker-def.png`),
        iconSize: [20, 20],
        iconAnchor: [10, 20],
        popupAnchor: [0, -15],
        className: 'marker'
      }),
      iconDefault: icon({
        iconUrl: require(`../assets/icon/icon-marker.png`),
        iconSize: [20, 20],
        iconAnchor: [10, 20],
        popupAnchor: [0, -15]
      }),
      zoom: 7,
      center: latLng(42.580388, 74.440613),
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      currentZoom: 7,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      markers: []
    }
  },
  computed: {
    ...mapGetters('herbarium', ['getData', 'isLoading']),
    ...mapGetters(['languageTake'])
  },
  watch: {
    lngLat: {
      handler: function(val) {
        if (val.lat && val.lng) {
          this.markers[0] = { latlng: latLng(+val.lat, +val.lng) }
        }
      },
      deep: true
    }
  },
  async created() {
    delete L.Icon.Default.prototype._getIconUrl
    setTimeout(() => {
      this.$refs.mymap.mapObject.invalidateSize()
    }, 100)

    if (this.readOnly) {
      await this.fetchDataFilter(`/herbarium?species=${this.specicesId}`)
      this.getData.forEach(el => {
        this.markers.push({
          id: el.id,
          ruName: el.ruName,
          enName: el.enName,
          latlng: latLng(+el.latitude, +el.longitude)
        })
      })
    } else {
      if (this.coordinates.longitude && this.coordinates.latitude) {
        this.markers[0] = {
          latlng: latLng(this.coordinates.latitude, this.coordinates.longitude)
        }
        this.lngLat.lat = this.coordinates.latitude
        this.lngLat.lng = this.coordinates.longitude
      }
    }
  },
  methods: {
    ...mapActions('herbarium', ['fetchDataFilter']),
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    innerClick(id) {
      if (id !== this.itemId) {
        this.$router
          .push({ name: 'herbariumItem', params: { id } })
          .catch(() => {})
      }
    },
    addMarker(event) {
      if (!this.readOnly) {
        this.markers[0] = { latlng: event.latlng }
        this.lngLat = {
          lat: event.latlng.lat.toFixed(6),
          lng: event.latlng.lng.toFixed(6)
        }
      }
    },
    removeMarker() {
      if (!this.readOnly) {
        this.markers = []
        this.lngLat = {
          lat: 0,
          lng: 0
        }
      }
    },
    saveCoord() {
      this.$root.$emit('bv::hide::modal', 'modalMap')
      this.$emit('setCoord', this.markers[0].latlng)
    }
  }
}
</script>
<style lang="scss" scoped>
.map-wrap {
  height: 750px;
  width: 100%;
  .information {
    position: absolute;
    min-width: 175px;
    top: 30px;
    z-index: 1000;
    right: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(85, 85, 102, 0.1);
    border-radius: 4px;
    padding: 1rem;
  }
}
.marker {
  z-index: 1000;
}
</style>
