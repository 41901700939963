<template>
  <div class="text-left">
    <label class="typo__label">
      {{ $t('herbarium_form_page_input_tags') }}
    </label>
    <Multiselect
      v-model="value"
      :select-label="$t('herbarium_form_page_select_label_tags')"
      label="name"
      track-by="code"
      :options="options"
      :multiple="true"
      :taggable="true"
      :placeholder="$t('herbarium_form_page_label_tags')"
      @tag="addTag"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from '../axiosApi'

export default {
  components: {
    Multiselect
  },
  props: {
    customchange: {
      type: Function,
      default: () => new Function()
    },
    values: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: [],
      options: []
    }
  },
  watch: {
    value: function(newVal) {
      this.customchange(newVal)
    }
  },
  async mounted() {
    const { data } = await axios.get('/tag')
    this.options = data
    if (this.values) {
      this.value = this.values.map(el => ({
        code: el.herbariumTag.tagId,
        name: el.name
      }))
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      }
      this.options.push(tag)
      this.value.push(tag)
    }
  }
}
</script>

<style src="" lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';
.multiselect {
  input {
    background-color: #f7f7f7;
  }
  .multiselect__tags {
    background-color: #f7f7f7;
    border: none;
    outline: none;
  }

  .multiselect__placeholder {
    display: none;
  }
}
</style>
