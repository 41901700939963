<template>
  <div v-if="errorAuth" class="container mt-5">
    <div class="confrim">
      <span>{{ errorAuth }}</span>
      <p>{{ $t('confirmation_registr_page_error_auth') }}</p>
    </div>
  </div>
  <div v-else>
    <div class="container mt-5">
      <div class="confirm">
        {{ $t('confirmation_registr_page_success_auth') }}
        <router-link to="/login">
          {{ $t('confirmation_registr_page_btn_login') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ConfirmationRegistr',
  data() {
    return {
      token: {
        hash: this.$route.query.hash
      }
    }
  },
  computed: mapGetters(['errorAuth']),
  mounted() {
    this.confirmationRegistr(this.token)
  },
  methods: mapActions(['confirmationRegistr'])
}
</script>

<style scoped>
.confirm {
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
  border-radius: 4px;
}
</style>
