import axios from '../axiosApi'

const initialState = {
  data: [],
  filteredData: [],
  item: {},
  error: '',
  loading: false,
  count: '',
  pages: '',
  family: []
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setLoading(state) {
      state.loading = !state.loading
    },
    getData(state, { result, count }) {
      state.data = result
      state.count = count
    },
    getFilteredData(state, data) {
      state.filteredData = data
    },
    getItem(state, item) {
      state.item = item
    },
    fetchDelete(state, idDelete) {
      state.data = state.data.filter(item => item.id !== idDelete)
    },
    errorInfo(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = ''
    },
    addItem(state, newItem) {
      state.newItem = newItem
    },
    changeItem(state, changeItem) {
      state.data = state.data.map(item =>
        item.id === changeItem.id ? changeItem : item
      )
    },
    addClassificationSearch(state, result) {
      state.family = result
    },
    clearStatePlants(state) {
      Object.assign(state, initialState)
    }
  },
  actions: {
    fetchData({ commit, dispatch }, url = '/plants') {
      commit('setLoading')
      return axios
        .get(url)
        .then(({ data }) => {
          commit('getData', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            {
              type: 'danger',
              message: data.code
            },
            { root: true }
          )
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    fetchItem({ commit, dispatch }, id) {
      commit('setLoading')
      return axios
        .get(`/plants/${id}`)
        .then(data => {
          commit('getItem', data.data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            {
              type: 'danger',
              message: data.code
            },
            { root: true }
          )
          commit('errorInfo', `${data.code}`)
        })
    },
    fetchDataFilter({ commit, dispatch }, url = '/plants/filter') {
      commit('setLoading')
      return axios
        .get(url)
        .then(({ data }) => {
          commit('getFilteredData', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            {
              type: 'danger',
              message: data.code
            },
            { root: true }
          )
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    deleteItem({ commit, dispatch }, id) {
      commit('setLoading')
      return axios
        .delete(`/plants/${id}`)
        .then(() => {
          commit('fetchDelete', id)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            {
              type: 'danger',
              message: data.code
            },
            { root: true }
          )
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    addItemToDB({ commit, dispatch }, item) {
      commit('setLoading')
      commit('clearError')
      return axios
        .post('/plants', item)
        .then(newItem => {
          commit('addItem', newItem.data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            {
              type: 'danger',
              message: data.code
            },
            { root: true }
          )
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    changeItemToDB({ commit, dispatch }, data) {
      commit('setLoading')
      commit('clearError')
      return axios
        .put(`/plants/${data.id}`, data.form)
        .then(changeItem => {
          commit('changeItem', changeItem.data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch('showToast', {
            type: 'danger',
            message: data.code
          })
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    classificationSearch({ commit, dispatch }, query) {
      commit('setLoading')
      commit('clearError')
      return axios
        .get(`/classification?search=${query}`)
        .then(({ data }) => {
          commit('addClassificationSearch', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          dispatch(
            'showToast',
            {
              type: 'danger',
              message: data.code
            },
            { root: true }
          )
          commit('errorInfo', `${data.code}`)
          commit('setLoading')
        })
    },
    clearErrorAction({ commit }) {
      commit('clearError')
    }
  },
  getters: {
    getData(state) {
      return state.data
    },
    getFilteredData(state) {
      return state.filteredData
    },
    getItem(state) {
      return state.item
    },
    getErrorPlants(state) {
      return state.error
    },
    isLoading(state) {
      return state.loading
    },
    getPaginationData(state) {
      return state.count
    },
    getFamily(state) {
      return state.family
    }
  }
}
