import Vue from 'vue'
import VueRouter from 'vue-router'
import ConfirmationRegistr from '../views/ConfirmationRegistr.vue'
import HerbariumItemPage from '../views/HerbariumItemPage.vue'
import SpecimentForm from '../components/SpecimentFormPage'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    component: () => import('../views/WelcomePage.vue')
  },
  {
    path: '/main',
    name: 'MainPage',
    component: () => import('../views/MainPage.vue'),
    children: [
      {
        path: 'herbarium/add',
        name: 'HerbariumCreate',
        meta: { access: ['editor', 'admin'] },
        props: true,
        component: () => import('../components/SpecimentFormPage.vue')
      },
      {
        path: 'herbarium',
        name: 'AllHerbarium',
        component: () => import('../views/Herbarium.vue'),
        redirect: { name: 'HerbariumGrid' },
        children: [
          {
            path: 'grid',
            name: 'HerbariumGrid',
            component: () => import('../views/HerbariumGrid.vue')
          },
          {
            path: 'list',
            name: 'HerbariumList',
            component: () => import('../views/HerbariumLists.vue')
          }
        ]
      },
      {
        path: 'herbarium/:id',
        name: 'herbariumItem',
        component: HerbariumItemPage
      },
      {
        path: 'species/:id',
        name: 'speciesPage',
        component: () => import('../views/SpeciesPage.vue')
      },
      // {
      //   path: 'actions',
      //   name: 'UserActions',
      //   meta: { access: ['user', 'editor', 'admin'] },
      //   component: () => import('../components/UserActions')
      // },
      {
        path: 'handbooks',
        name: 'userHerbariums',
        component: () => import('../components/Handbooks/HandbooksMain'),
        redirect: { name: 'userHerbariumsHandbooks' },
        meta: { access: ['editor', 'admin'] },
        children: [
          {
            path: 'plants',
            name: 'userHerbariumsHandbooks',
            meta: { access: ['editor', 'admin'] },
            component: () =>
              import('../components/Handbooks/PlantsHandbook.vue')
          },
          {
            path: 'plants/add',
            name: 'userHerbariumsAddPlant',
            meta: { access: ['user', 'editor', 'admin'] },
            component: () => import('../components/PlantsFormPage.vue')
          },
          {
            path: 'classification',
            name: 'userHerbariumsClasses',
            meta: { access: ['editor', 'admin'] },
            component: () =>
              import('../components/Handbooks/ClassificationsHandbook.vue')
          },
          {
            path: 'images',
            name: 'userHerbariumsImages',
            meta: { access: ['editor', 'admin'] },
            component: () =>
              import('../components/Handbooks/ImagesHandbook.vue')
          },
          {
            path: 'images/:id',
            name: 'changeHerbariumItem',
            meta: { access: ['editor', 'admin'] },
            component: SpecimentForm
          }
        ]
      },
      {
        path: 'users',
        name: 'profileUsers',
        props: true,
        meta: { access: ['admin'] },
        component: () => import('../components/Users/Users')
      }
    ]
  },
  {
    path: '/partners',
    name: 'OurPartners',
    component: () => import('../views/PartnersPage.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: { noNavbar: true },
    component: () => import('../views/RegisterPage.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { noNavbar: true },
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/password/recovery',
    name: 'recovery',
    meta: { noNavbar: true },
    component: () => import('../views/RecoveryPasswordPage.vue')
  },
  {
    path: '/email/confirm',
    name: 'confirm_email',
    component: ConfirmationRegistr
  },
  {
    path: '/tags',
    name: 'showTags',
    component: () => import('../views/ShowHerbariumTags.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { access: ['user', 'editor', 'admin'] },
    component: () => import('../views/Profile.vue'),
    children: [
      {
        path: 'info',
        name: 'userInfo',
        meta: { access: ['user', 'editor', 'admin'] },
        component: () => import('../components/Profile/UserInfo')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuth = localStorage.getItem('token')
  const requireAuth = to.matched.some(record => record.meta.auth)

  switch (true) {
    case requireAuth && !isAuth:
      return next('/')
    case (to.name === 'login' || to.name === 'register') && isAuth:
      return next('/')
    default:
      return next()
  }
})

router.beforeResolve(async (to, from, next) => {
  let user = ''
  const token = localStorage.getItem('token')
  if (token) {
    user = await store.dispatch('checkToken')
  }
  switch (true) {
    case !!to.meta.access:
      return to.meta.access.find(role => role === user.role.name)
        ? next()
        : next('/login')
    default:
      return next()
  }
})

export default router
