import axios from '../axiosApi'

const initialState = {
  users: [],
  count: null
}

export default {
  state: { ...initialState },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
    setCount(state, count) {
      state.count = count
    },
    setUser(state, user) {
      const users = [...state.users]
      const index = users.findIndex(el => el.id === user.id)
      users[index] = user
      state.users = users
    },
    clearStateUsers(state) {
      Object.assign(state, initialState)
    }
  },
  actions: {
    async requestUsers({ commit, dispatch }, query) {
      const { perPage, name, page, sortBy, sortDesc } = query
      return axios
        .get(
          `users/all?perPage=${perPage}&name=${name}&page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}`
        )
        .then(({ data }) => {
          commit('setUsers', data.rows)
          commit('setCount', data.count)
        })
        .catch(({ data }) =>
          dispatch('showToast', {
            type: 'danger',
            message: data.code
          })
        )
    },
    async requestEditUser({ commit, dispatch }, user) {
      return axios
        .put(`/users/edit/${user.id}`, user)
        .then(({ data }) => {
          commit('setUser', data)
          dispatch('showToast', {
            type: 'success',
            message: 'toast_user_edit_compleat'
          })
        })
        .catch(({ data }) => {
          dispatch('showToast', {
            type: 'danger',
            message: data.code
          })
        })
    }
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    getCount(state) {
      return state.count
    },
    getUserById(state) {
      return id => {
        const user = state.users.find(el => el.id === id)
        return { ...user }
      }
    }
  }
}
