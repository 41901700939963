import axios from '../axiosApi'
import router from '../router'

const initialState = {
  token: '',
  auth_error: '',
  userData: {},
  response: '',
  language: 'en',
  loading: false
}

export default {
  state: { ...initialState },
  mutations: {
    registerNewUser(state, response) {
      state.response = response
    },
    passwordRecovery(state, response) {
      state.response = response
    },
    auth_success(state, { token, user }) {
      state.token = token
      state.userData = user
    },
    auth_error(state, auth_error) {
      state.auth_error = auth_error
    },
    clear_state_auth(state) {
      Object.assign(state, initialState)
    },
    setLoading(state) {
      state.loading = !state.loading
    },
    clearError(state) {
      state.auth_error = ''
    },
    defineLang(state, language) {
      state.language = language
    },
    changeAccess(state, { status, role }) {
      state.userData[role] = status
    },
    changeEditor(state, status) {
      state.userData.editor = status
    },
    changeAdmin(state, status) {
      state.userData.admin = status
    },
    newUserData(state, data) {
      Object.keys(data).forEach(el => {
        state.userData[el] = data[el]
      })
    }
  },
  actions: {
    registerUser({ commit, dispatch }, user) {
      commit('clear_state_auth')
      commit('setLoading')
      return axios
        .post('/auth/register', user)
        .then(newUser => {
          dispatch('showToast', {
            type: 'success',
            message: 'toast_register_success'
          })
          commit('registerNewUser', newUser.data.message)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('auth_error', `${data.code}`)
          commit('setLoading')
          dispatch('showToast', {
            type: 'danger',
            message: data.code
          })
        })
    },
    loginUser({ commit, dispatch }, { user, path }) {
      localStorage.removeItem('token')
      commit('clear_state_auth')
      commit('setLoading')
      return axios
        .post(`${path}`, user)
        .then(({ data }) => {
          localStorage.setItem('token', data.token)
          commit('auth_success', data)
          commit('setLoading')
          dispatch('showToast', {
            type: 'success',
            message: 'toast_auth_success'
          })
        })
        .catch(({ data }) => {
          commit('auth_error', `${data.code}`)
          localStorage.removeItem('token')
          commit('setLoading')
          dispatch('showToast', {
            type: 'danger',
            message: data.code
          })
        })
    },
    loginOut({ dispatch }) {
      localStorage.removeItem('token')
      dispatch('clearAllState')
      router.push('/login').catch(() => {})
    },
    recoverPWD({ commit, dispatch }, email) {
      commit('setLoading')
      return axios
        .post(`/auth/password/recovery`, { email: email })
        .then(() => {
          dispatch('showToast', {
            type: 'success',
            message: 'toast_recovery_password_success'
          })
          commit('setLoading')
          return { success: true }
        })
        .catch(() => {
          dispatch('showToast', {
            type: 'danger',
            message: 'toast_auth_change_password_error'
          })
          commit('setLoading')
        })
    },
    confirmationRegistr({ commit }, confirmationHash) {
      commit('clear_state_auth')
      return axios
        .post(`/auth/confirmation`, confirmationHash)
        .catch(({ data }) => {
          commit('auth_error', `${data.code}`)
        })
    },
    checkToken({ commit }) {
      return axios
        .get('/auth/users')
        .then(({ data }) => {
          const user = { ...data }
          const token = localStorage.getItem('token')
          commit('auth_success', { token, user })
          return data
        })
        .catch(() => {
          localStorage.removeItem('token')
        })
    },
    clearError({ commit }) {
      commit('clearError')
    },
    defineLanguage({ commit }) {
      const language = localStorage.getItem('language')
      commit('defineLang', `${language}`)
    },
    changeLanguage({ commit }, lang) {
      commit('defineLang', lang)
    },
    requestAccess({ commit }, url) {
      commit('setLoading')
      return axios
        .get(`/access?${url}`)
        .then(({ data }) => {
          commit('setLoading')
          commit('changeAccess', data)
        })
        .catch(({ data }) => {
          commit('setLoading')
          commit('auth_error', `${data.code}`)
        })
    },
    changePassword({ dispatch }, data) {
      return axios
        .post('/auth/password/change', data)
        .then(() => {
          dispatch('showToast', {
            type: 'success',
            message: 'toast_auth_change_password_success'
          })
          return { success: true }
        })
        .catch(({ data }) => {
          dispatch('showToast', {
            type: 'danger',
            message: data.code
          })
        })
    },
    editProfile({ commit, dispatch }, data) {
      return axios
        .post('/auth/profile/edit', data)
        .then(({ data }) => {
          commit('newUserData', data.data)
          dispatch('showToast', {
            type: 'success',
            message: 'toast_profile_edit_success'
          })
          return { success: true }
        })
        .catch(() => {
          dispatch('showToast', {
            type: 'danger',
            message: 'toast_auth_change_password_error'
          })
        })
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.token
    },
    author(state) {
      return state.userData.firstname
    },
    errorAuth(state) {
      return state.auth_error
    },
    getResponse(state) {
      return state.response
    },
    isLoading(state) {
      return state.loading
    },
    languageTake(state) {
      return state.language
    },
    userData(state) {
      return state.userData
    },
    getRole(state) {
      const userRole = state.userData.role
      if (!userRole) return null
      return userRole.name
    }
  }
}
