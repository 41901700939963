<template>
  <div>
    <section v-if="Object.keys(getItem).length" class="container my-5">
      <div class="herbItem">
        <div class="row d-flex justify-content-between mb-4">
          <div
            class="col-12 d-flex justify-content-around flex-column flex-md-row"
          >
            <h4>{{ $t('herbarium_item_page_title') }}</h4>
            <div>
              <b-button
                v-if="isLoggedIn"
                variant="success"
                class="mr-2"
                @click="edit"
              >
                <b-icon icon="pencil" class="icon" />
              </b-button>
              <b-button v-if="isLoggedIn" variant="danger" @click="showModal">
                <b-icon icon="trash-fill" class="icon" />
              </b-button>
            </div>
          </div>
        </div>
        <div class="row flex-column flex-md-row">
          <div class="col-12 col-md-5 mb-4">
            <div style="max-width: 450px;">
              <ZoomImage :image-url="getItem.image" />
            </div>
          </div>
          <div class="col-12 col-md-6 mb-4 ml-md-4">
            <div class="row">
              <div class="col-12 col-md-5 text-left mb-4 mb-md-0">
                <h5>{{ $t('herbarium_form_page_classification_title') }}</h5>
              </div>
              <div class="text-left col-12 col-md-7">
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_family_label') }}
                  </strong>
                  {{
                    getItem.plant.category && getItem.plant.category.category
                      ? getItem.plant.category.category.laName
                      : '-'
                  }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_genus_label') }}
                  </strong>
                  {{
                    getItem.plant.category ? getItem.plant.category.laName : '-'
                  }}
                </p>
                <p
                  class="showSpeciesPage"
                  @click="showSpecies(getItem.plant.id)"
                >
                  <strong>
                    {{ $t('herbarium_form_page_input_species_label') }}
                  </strong>
                  {{ getItem.plant.laName }}
                  <b-icon
                    icon="eye-fill"
                    variant="success"
                    class="ml-2 icon-eye"
                  ></b-icon>
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_sub_species_label') }}
                  </strong>
                  {{ getItem.plant.subSpecies }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_genus_holotype') }}
                  </strong>
                  {{ getItem.plant.holotypeId }}
                </p>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 col-md-5 text-left mb-4 mb-md-0">
                <h5>{{ $t('herbarium_form_page_name_title') }}</h5>
              </div>
              <div class="text-left col-12 col-md-7">
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_enName_label') }}
                  </strong>
                  {{ getItem.enName }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_ruName_label') }}
                  </strong>
                  {{ getItem.ruName }}
                </p>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 col-md-5 text-left mb-4 mb-md-0">
                <h5>{{ $t('herbarium_form_page_picking_title') }}</h5>
              </div>
              <div class="text-left col-12 col-md-7">
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_area_label') }}
                  </strong>
                  {{ getItem.area }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_zone_label') }}
                  </strong>
                  {{ getItem.zone }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_ridge_label') }}
                  </strong>
                  {{ getItem.ridge }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_habitation_label') }}
                  </strong>
                  {{ getItem.habitation }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_ground_label') }}
                  </strong>
                  {{ getItem.ground }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_phenology_label') }}
                  </strong>
                  {{ getItem.phenology }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_elevation_label') }}
                  </strong>
                  {{ getItem.elevation }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_natural_boundary_label') }}
                  </strong>
                  {{ getItem.naturalBoundary }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_datepicker_label') }}
                  </strong>
                  {{ getItem.collectedDate }}
                </p>
                <p>
                  <button class="mapBtn mt-2" @click="openMap">
                    {{ $t('herbarium_form_page_show_map') }}
                    <img
                      src="../assets/icon/icon-navigate-circle.svg"
                      alt="gps"
                      class="gpsicon"
                    />
                  </button>
                </p>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 col-md-5 text-left mb-4 mb-md-0">
                <h5>{{ $t('herbarium_form_page_other_fields_title') }}</h5>
              </div>
              <div class="text-left col-12 col-md-7">
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_colour_of_flower_label') }}
                  </strong>
                  {{ getItem.colourOfFlower }}
                </p>
                <p>
                  <strong>
                    {{
                      $t('herbarium_form_page_input_specimentCollector_label')
                    }}
                  </strong>
                  {{ getItem.specimentCollector }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_catalogueNumber_label') }}
                  </strong>
                  {{ getItem.catalogueNumber }}
                </p>
                <p>
                  <strong>
                    {{ $t('herbarium_form_page_input_photographer_label') }}
                  </strong>
                  {{ getItem.photographer }}
                </p>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 col-md-5 text-left mb-4 mb-md-0">
                <h5>{{ $t('herbarium_form_page_input_tags') }}</h5>
              </div>
              <div class="text-left col-12 col-md-7">
                <b-badge
                  v-for="tag of getItem.tags"
                  :key="tag.name"
                  variant="light"
                  class="ml-1 p-3"
                >
                  <router-link
                    :to="{
                      path: '/main/herbarium/grid',
                      query: {
                        tag: tag.herbariumTag.tagId,
                        limit: $store.getters.getPerPage,
                        offset: 1
                      }
                    }"
                    replace
                    class="link"
                  >
                    {{ tag.name }}
                  </router-link>
                </b-badge>
              </div>
            </div>
          </div>
          <div class="text-left col-12 mt-3 pl-4">
            <h5 class="pl-3">{{ $t('plant_item_page_all_herbarium') }}</h5>
            <div class="herbariumList">
              <div class="table-wrap">
                <b-table
                  id="herbarium-table"
                  hover
                  :items="getData"
                  :fields="fields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  @row-clicked="item => goTodetail(item.id)"
                >
                  <template #head()="data">
                    <div>
                      <span>{{ $t(data.label) }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(image)="row">
                    <img class="plantImage" :src="`${row.value}`" />
                  </template>
                  <template v-slot:cell(ruName)="row">
                    <span>{{ row.item.ruName }}</span>
                  </template>
                  <template v-slot:cell(enName)="row">
                    <span>{{ row.item.enName }}</span>
                  </template>
                  <template v-slot:cell(collectedDate)="row">
                    <span>{{ row.item.collectedDate }}</span>
                  </template>
                  <template v-slot:cell(phenology)="row">
                    <span>{{ row.item.phenology }}</span>
                  </template>
                  <template v-slot:table-caption>
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="getCount"
                      size="sm"
                      first-number
                      last-number
                      aria-controls="herbarium-table"
                      class="mt-4"
                    />
                  </template>
                </b-table>
              </div>
              <div v-if="isLoading" class="pb-5">
                <div class="m-auto">
                  <b-spinner
                    variant="success"
                    label="Text Centered"
                  ></b-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isLoading && !Object.keys(getItem).length">
          <div class="text-center">
            <b-spinner variant="success" label="Text Centered"></b-spinner>
          </div>
        </div>
      </div>
      <Modal
        :confirmation="deleteItem"
        :title="$t('component_modal_herbarium_delete')"
        id-modal="modalDelete"
      />
      <Modal
        :title="$t('herbarium_form_page_input_gps_map_label')"
        id-modal="showMap"
        size="xl"
      >
        <template #modalBody>
          <Map :item-id="+proId" :specices-id="+getItem.plant.id" read-only />
        </template>
      </Modal>
    </section>
    <Modal
      :title="$t('herbarium_form_page_title_editor')"
      id-modal="editHerbariumModal"
      size="xl"
    >
      <template #modalBody>
        <SpecimentFormPage :header="false" is-edit />
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/Modal.vue'
import dateFormat from 'dateformat'
import SpecimentFormPage from '@/components/SpecimentFormPage'
import Map from '@/components/Map.vue'
import ZoomImage from '@/components/ZoomImage'

export default {
  name: 'HerbariumItem',
  components: {
    Modal,
    SpecimentFormPage,
    Map,
    ZoomImage
  },
  data() {
    return {
      proId: this.$route.params.id,
      checkbox: [],
      render: false,
      speciesID: this.$route.params.id,
      currentPage: 1,
      perPage: 5,
      fields: [
        {
          key: 'image',
          thClass: 'thead_',
          label: 'plant_item_page_list_photo'
        },
        {
          key: 'ruName',
          thClass: 'thead_',
          label: 'plant_item_page_list_ruName'
        },
        {
          key: 'enName',
          thClass: 'thead_',
          label: 'plant_item_page_list_enName'
        },
        {
          key: 'collectedDate',
          thClass: 'thead_',
          label: 'plant_item_page_list_collectedDate'
        },
        {
          key: 'phenology',
          thClass: 'thead_',
          label: 'plant_item_page_list_phenology'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('herbarium', ['getItem', 'isLoading', 'getCount', 'getData']),
    ...mapGetters(['isLoggedIn']),
    plantData() {
      return this.getData[0].plant
    }
  },
  async created() {
    await this.fetchItem(this.proId).then(() => {
      this.fetchDataFilter(`/herbarium?species=${this.getItem.plant.id}`)
    })
  },
  destroyed() {
    // this.$store.commit('herbarium/clearStateHerbarium')
    this.clearItemInfo()
  },
  methods: {
    ...mapActions('herbarium', [
      'fetchItem',
      'deleteItem',
      'clearItemInfo',
      'fetchDataFilter'
    ]),
    deleteItem() {
      this.$store.dispatch('deleteItem', this.proId).then(() => {
        this.$router.push('/').catch(() => {})
      })
    },
    changeItem() {
      this.$router.push(`/herbarium/edit/${this.proId}`).catch(() => {})
    },
    refDate(date) {
      return dateFormat(date, 'yyyy-mm-dd')
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'modalDelete')
    },
    edit() {
      this.$root.$emit('bv::show::modal', 'editHerbariumModal')
    },
    openMap() {
      this.$root.$emit('bv::show::modal', 'showMap')
    },
    showSpecies(speciesID) {
      this.$router.push(`/main/species/${speciesID}`).catch(() => {})
    },
    goTodetail(itemId) {
      this.$router
        .push({ name: 'herbariumItem', params: { id: itemId } })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .herbItem {
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
    border-radius: 4px;
    .imageHerbarium {
      height: 300px;
      width: auto;
    }
    .icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    .mapBtn {
      background: #f1f1f1;
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 5px 10px;
      &:hover {
        background: #d1d1d1;
      }
    }
  }
  .badge-info {
    background-color: #4b996a;
    letter-spacing: 1px;
    a {
      color: #fff !important;
    }
  }
}
.link {
  color: #5fce9c;
}
.showSpeciesPage {
  cursor: pointer;
  .icon-eye:hover {
    color: #5a5959 !important;
  }
  &:hover {
    color: #4b996a !important;
  }
}

.herbariumList {
  .plantImage {
    height: 80px;
    width: auto;
  }
  .thead_ {
    background: #f6f6f6;
    color: #373a3c;
    font-size: 0.9rem;
    border: none;
    vertical-align: middle;
    border-radius: 4px 4px 0px 0px;
    padding: 0.4rem 0 0.4rem 2rem;
    text-align: left;
    @media (max-width: 768px) {
      line-height: 1.5;
      font-size: 0.8rem;
    }
    &:last-child {
      min-width: 50px;
    }
  }
  /deep/.table-wrap {
    position: relative;
    min-height: 380px;
    .b-table {
      caption {
        position: absolute;
        bottom: 0;
      }
      tbody {
        tr {
          cursor: pointer;
          &:hover {
            background-color: #f4f8ff;
          }
          td {
            border-top: none;
            border-bottom: 1px solid #f1f1f1;
            text-align: left;
            vertical-align: middle;
            padding: 0.9rem 0 0.9rem 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }
}
</style>
