<template>
  <section :class="[isEdit ? '' : 'mb-5']">
    <div class="herbForm">
      <div v-if="header">
        <h5 class="text-center text-md-left">
          {{
            isEdit
              ? $t('herbarium_form_page_title_editor')
              : $t('herbarium_form_page_title_creator')
          }}
        </h5>
        <hr class="my-4" />
      </div>
      <div>
        <b-form @submit.prevent="isEdit ? changeItemToDB() : addItemToDB()">
          <div class="row my-4 flex-column flex-md-row">
            <div class="col-12 col-md-3 text-left mb-4 mb-md-0">
              <h6>{{ $t('herbarium_form_page_classification_title') }}</h6>
            </div>
            <div class="col-12 col-md-9 d-flex flex-column">
              <div class="col-12 col-md-6">
                <b-form-group
                  id="input-group-family"
                  :label="$t('herbarium_form_page_input_family_label')"
                  label-for="family"
                  class="text-left"
                >
                  <Multiselect
                    id="family"
                    v-model="family"
                    label="laName"
                    track-by="id"
                    :placeholder="$t('herbarium_form_page_label_tags')"
                    :select-label="$t('vue_multi_select_enter')"
                    :deselect-label="$t('vue_multi_select_remove')"
                    :selected-label="$t('vue_multi_select_selected_label')"
                    open-direction="bottom"
                    :options="familyData"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :multiple="false"
                    :max-height="600"
                    :show-no-results="false"
                    :hide-selected="false"
                    @search-change="searchFamily"
                  >
                    <template slot="noOptions">
                      <span>{{ $t('vue_multi_select_no_options') }}</span>
                    </template>
                    <template slot="noResult">
                      <span>{{ $t('vue_multi_select_no_result') }}</span>
                    </template>
                  </Multiselect>
                </b-form-group>
                <b-form-group
                  id="input-group-genus"
                  :label="$t('herbarium_form_page_input_genus_label')"
                  label-for="genus"
                  class="text-left"
                >
                  <Multiselect
                    id="genus"
                    v-model="genus"
                    label="laName"
                    track-by="id"
                    :placeholder="$t('herbarium_form_page_label_tags')"
                    :select-label="$t('vue_multi_select_enter')"
                    :deselect-label="$t('vue_multi_select_remove')"
                    :selected-label="$t('vue_multi_select_selected_label')"
                    open-direction="bottom"
                    :options="genusData"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :max-height="600"
                    :show-no-results="false"
                    :hide-selected="false"
                    :multiple="false"
                    :disabled="!family"
                    @search-change="searchGenus"
                  >
                    <template slot="noOptions">
                      <span>{{ $t('vue_multi_select_no_options') }}</span>
                    </template>
                    <template slot="noResult">
                      <span>{{ $t('vue_multi_select_no_result') }}</span>
                    </template>
                  </Multiselect>
                </b-form-group>
                <b-form-group
                  id="input-group-species"
                  :label="$t('herbarium_form_page_input_species_label')"
                  label-for="species"
                  class="text-left"
                >
                  <Multiselect
                    id="species"
                    v-model="form.species"
                    label="name"
                    track-by="code"
                    :placeholder="$t('herbarium_form_page_label_tags')"
                    :select-label="$t('vue_multi_select_enter')"
                    :deselect-label="$t('vue_multi_select_remove')"
                    :selected-label="$t('vue_multi_select_selected_label')"
                    open-direction="bottom"
                    :options="classificationData"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :max-height="600"
                    :show-no-results="false"
                    :hide-selected="false"
                    :multiple="false"
                    :disabled="!genus"
                    @search-change="search"
                  >
                    <template slot="noOptions">
                      <span>{{ $t('vue_multi_select_no_options') }}</span>
                    </template>
                    <template slot="noResult">
                      <span>{{ $t('vue_multi_select_no_result') }}</span>
                    </template>
                  </Multiselect>
                </b-form-group>
                <b-form-group
                  id="input-group-sub-species"
                  :label="$t('herbarium_form_page_input_sub_species_label')"
                  label-for="sub-species"
                  class="text-left"
                >
                  <b-form-input
                    id="sub-species"
                    v-model="subSpecies"
                    type="text"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-HolotypeId"
                  :label="$t('herbarium_form_page_input_holotypeId_label')"
                  label-for="HolotypeId"
                  class="text-left"
                >
                  <b-form-input
                    id="HolotypeId"
                    v-model="holotypeId"
                    type="text"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <hr />
          <div class="row my-4 flex-column flex-md-row">
            <div class="col-12 col-md-3 text-left  mb-4 mb-md-0">
              <h6>{{ $t('herbarium_form_page_name_title') }}</h6>
            </div>
            <div
              class="d-flex justify-content-between flex-column flex-md-row col-12 col-md-9"
            >
              <div class="col-12 col-md-6">
                <b-form-group
                  id="input-group-ruName"
                  :label="$t('herbarium_form_page_input_ruName_label')"
                  label-for="ruName"
                  class="text-left"
                >
                  <b-form-input
                    id="ruName"
                    v-model="$v.form.ruName.$model"
                    type="text"
                    :state="validateState('ruName')"
                  />
                  <b-form-text
                    v-if="$v.form.ruName.$dirty && !$v.form.ruName.required"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_empty') }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.ruName.$dirty && !$v.form.ruName.maxLength"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.ruName.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.ruName.$dirty && !$v.form.ruName.alphaRU"
                    text-variant="danger"
                  >
                    {{
                      $t('profile_classification_modal_ru_input_valid_message')
                    }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  id="input-group-enName"
                  :label="$t('herbarium_form_page_input_enName_label')"
                  label-for="enName"
                  class="text-left"
                >
                  <b-form-input
                    id="enName"
                    v-model.trim="$v.form.enName.$model"
                    :state="validateState('enName')"
                    type="text"
                  />
                  <b-form-text
                    v-if="$v.form.enName.$dirty && !$v.form.enName.required"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_empty') }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.enName.$dirty && !$v.form.enName.maxLength"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.enName.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.enName.$dirty && !$v.form.enName.alphaEN"
                    text-variant="danger"
                  >
                    {{
                      $t('profile_classification_modal_en_input_valid_message')
                    }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  id="input-group-image"
                  :label="$t('herbarium_form_page_input_image_label')"
                  label-for="image"
                  class="text-left"
                >
                  <b-form-file
                    id="image"
                    accept="image/jpg, image/jpeg, image/png"
                    :placeholder="$t('herbarium_form_page_image_placeholder')"
                    :browse-text="$t('herbarium_form_page_file_input_browse')"
                    @change="loadFile($event)"
                  />
                </b-form-group>
              </div>
              <div class="col-5 m-auto">
                <ZoomImage
                  v-if="url"
                  :image-url="url"
                  :width="'300px'"
                  :height="'380px'"
                />
              </div>
            </div>
          </div>
          <hr />
          <div class="row my-4 flex-column flex-md-row">
            <div class="col-12 col-md-3 text-left mb-4 mb-md-0">
              <h6>{{ $t('herbarium_form_page_picking_title') }}</h6>
            </div>
            <div class="col-12 col-md-9">
              <div class="text-left col-12">
                <label for="gps">
                  {{ $t('herbarium_form_page_input_gps_label') }}
                </label>
                <b-input-group
                  id="input-group-gps"
                  label-for="gps"
                  class="mb-2"
                >
                  <b-form-input id="gps" v-model="form.gps" disabled />
                  <b-input-group-append @click="showMap">
                    <img
                      src="../assets/icon/icon-navigate-circle.svg"
                      alt="gps"
                      class="gpsicon"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
              <!-- //////////////////////////// -->
              <div class="d-flex flex-column flex-md-row">
                <div class="col-12 col-md-4">
                  <b-form-group
                    id="input-group-area"
                    :label="$t('herbarium_form_page_input_area_label')"
                    label-for="area"
                    class="text-left"
                  >
                    <b-form-input
                      id="area"
                      v-model.trim="$v.form.area.$model"
                      :state="validateState('area')"
                      type="text"
                    />
                    <b-form-text
                      v-if="$v.form.area.$dirty && !$v.form.area.maxLength"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.area.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="$v.form.area.$dirty && !$v.form.area.alphaRUEN"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                  <b-form-group
                    id="input-group-zone"
                    :label="$t('herbarium_form_page_input_zone_label')"
                    label-for="zone"
                    class="text-left"
                  >
                    <b-form-input
                      id="zone"
                      v-model.trim="$v.form.zone.$model"
                      :state="validateState('zone')"
                      type="text"
                    />
                    <b-form-text
                      v-if="$v.form.zone.$dirty && !$v.form.zone.maxLength"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.zone.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="$v.form.zone.$dirty && !$v.form.zone.alphaRUEN"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                  <b-form-group
                    id="input-group-ridge"
                    :label="$t('herbarium_form_page_input_ridge_label')"
                    label-for="ridge"
                    class="text-left"
                  >
                    <b-form-input
                      id="ridge"
                      v-model.trim="$v.form.ridge.$model"
                      :state="validateState('ridge')"
                      type="text"
                    />
                    <b-form-text
                      v-if="$v.form.ridge.$dirty && !$v.form.ridge.maxLength"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.ridge.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="$v.form.ridge.$dirty && !$v.form.ridge.alphaRUEN"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                  <b-form-group
                    id="input-group-habitation"
                    :label="$t('herbarium_form_page_input_habitation_label')"
                    label-for="habitation"
                    class="text-left"
                  >
                    <b-form-input
                      id="habitation"
                      v-model.trim="$v.form.habitation.$model"
                      :state="validateState('habitation')"
                      type="text"
                    />
                    <b-form-text
                      v-if="
                        $v.form.habitation.$dirty &&
                          !$v.form.habitation.maxLength
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.habitation.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="
                        $v.form.habitation.$dirty &&
                          !$v.form.habitation.alphaRUEN
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                  <b-form-group
                    id="input-group-ground"
                    :label="$t('herbarium_form_page_input_ground_label')"
                    label-for="ground"
                    class="text-left"
                  >
                    <b-form-input
                      id="ground"
                      v-model.trim="$v.form.ground.$model"
                      :state="validateState('ground')"
                      type="text"
                    />
                    <b-form-text
                      v-if="$v.form.ground.$dirty && !$v.form.ground.maxLength"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.ground.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="$v.form.ground.$dirty && !$v.form.ground.alphaRUEN"
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                  <b-form-group
                    id="input-group-colour-of-flower"
                    :label="
                      $t('herbarium_form_page_input_colour_of_flower_label')
                    "
                    label-for="colour-of-flower"
                    class="text-left"
                  >
                    <b-form-input
                      id="colour-of-flower"
                      v-model="$v.form.colourOfFlower.$model"
                      :state="validateState('colourOfFlower')"
                      type="text"
                    />
                    <b-form-text
                      v-if="
                        $v.form.colourOfFlower.$dirty &&
                          !$v.form.colourOfFlower.maxLength
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.colourOfFlower.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="
                        $v.form.colourOfFlower.$dirty &&
                          !$v.form.colourOfFlower.alphaRUEN
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                  <b-form-group
                    id="input-group-phenology"
                    :label="$t('herbarium_form_page_input_phenology_label')"
                    label-for="phenology"
                    class="text-left"
                  >
                    <b-form-input
                      id="phenology"
                      v-model.trim="$v.form.phenology.$model"
                      :state="validateState('phenology')"
                      type="text"
                    />
                    <b-form-text
                      v-if="
                        $v.form.phenology.$dirty && !$v.form.phenology.maxLength
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.phenology.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="
                        $v.form.phenology.$dirty && !$v.form.phenology.alphaRUEN
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                  <b-form-group
                    id="input-group-elevation"
                    :label="$t('herbarium_form_page_input_elevation_label')"
                    label-for="elevation"
                    class="text-left"
                  >
                    <b-form-input
                      id="elevation"
                      v-model.trim="$v.form.elevation.$model"
                      :state="validateState('elevation')"
                      type="text"
                    />
                    <b-form-text
                      v-if="
                        $v.form.elevation.$dirty && !$v.form.elevation.maxLength
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}
                      {{ $v.form.elevation.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="
                        $v.form.elevation.$dirty && !$v.form.elevation.decimal
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_coordinates_error') }}
                    </b-form-text>
                  </b-form-group>
                  <b-form-group
                    id="input-group-natural-boundary"
                    :label="
                      $t('herbarium_form_page_input_natural_boundary_label')
                    "
                    label-for="natural-boundary"
                    class="text-left"
                  >
                    <b-form-input
                      id="natural-boundary"
                      v-model.trim="$v.form.naturalBoundary.$model"
                      :state="validateState('naturalBoundary')"
                      type="text"
                    />
                    <b-form-text
                      v-if="
                        $v.form.naturalBoundary.$dirty &&
                          !$v.form.naturalBoundary.maxLength
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_name_error_length') }}Mu
                      {{ $v.form.naturalBoundary.$params.maxLength.max }}
                    </b-form-text>
                    <b-form-text
                      v-if="
                        $v.form.naturalBoundary.$dirty &&
                          !$v.form.naturalBoundary.alphaRUEN
                      "
                      text-variant="danger"
                    >
                      {{ $t('herbarium_form_page_input_error_alpha') }}
                    </b-form-text>
                  </b-form-group>
                </div>
              </div>
              <!-- //////////////////////////// -->
              <div class="col-12">
                <b-form-group
                  id="input-group-datepicker"
                  :label="$t('herbarium_form_page_input_datepicker_label')"
                  label-for="datepicker"
                  class="text-left w-100"
                >
                  <b-form-datepicker
                    id="datepicker"
                    v-model="$v.form.collectedDate.$model"
                    :placeholder="
                      $t('herbarium_form_page_input_datepicker_placeholder')
                    "
                    :state="validateState('collectedDate')"
                    class="mb-2"
                    :locale="$t('current_lang')"
                    :label-help="$t('bottom_label_datepicker')"
                    :label-no-date-selected="$t('top_label_datepicker')"
                  />
                  <b-form-text
                    v-if="
                      $v.form.collectedDate.$dirty &&
                        !$v.form.collectedDate.range
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_datepicker_error') }}
                  </b-form-text>
                  <b-form-text
                    v-if="
                      $v.form.collectedDate.$dirty &&
                        !$v.form.collectedDate.required
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_empty') }}
                  </b-form-text>
                </b-form-group>
              </div>
            </div>
          </div>
          <hr />
          <div class="row my-4 flex-column flex-md-row">
            <div class="col-12 col-md-3 text-left mb-4 mb-md-0">
              <h6>{{ $t('herbarium_form_page_other_fields_title') }}</h6>
            </div>
            <div class="col-12 col-md-9 d-flex flex-column">
              <div class="col-12 col-md-6">
                <b-form-group
                  id="input-group-catalogueNumber"
                  :label="$t('herbarium_form_page_input_catalogueNumber_label')"
                  label-for="catalogueNumber"
                  class="text-left"
                >
                  <b-form-input
                    id="catalogueNumber"
                    v-model="$v.form.catalogueNumber.$model"
                    :state="validateState('catalogueNumber')"
                    type="text"
                  />
                  <b-form-text
                    v-if="
                      $v.form.catalogueNumber.$dirty &&
                        !$v.form.catalogueNumber.maxLength
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.catalogueNumber.$params.maxLength.max }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  id="input-group-specimentCollector"
                  :label="
                    $t('herbarium_form_page_input_specimentCollector_label')
                  "
                  label-for="specimentCollector"
                  class="text-left"
                >
                  <b-form-input
                    id="specimentCollector"
                    v-model="$v.form.specimentCollector.$model"
                    :state="validateState('specimentCollector')"
                    type="text"
                  />
                  <b-form-text
                    v-if="
                      $v.form.specimentCollector.$dirty &&
                        !$v.form.specimentCollector.maxLength
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.specimentCollector.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="
                      $v.form.specimentCollector.$dirty &&
                        !$v.form.specimentCollector.alphaRUEN
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_error_alpha') }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  id="input-group-specimentIdentifier"
                  :label="
                    $t('herbarium_form_page_input_specimentIdentifier_label')
                  "
                  label-for="specimentIdentifier"
                  class="text-left"
                >
                  <b-form-input
                    id="specimentIdentifier"
                    v-model="$v.form.specimentIdentifier.$model"
                    :state="validateState('specimentIdentifier')"
                    type="text"
                  />
                  <b-form-text
                    v-if="
                      $v.form.specimentIdentifier.$dirty &&
                        !$v.form.specimentIdentifier.maxLength
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.specimentIdentifier.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="
                      $v.form.specimentIdentifier.$dirty &&
                        !$v.form.specimentIdentifier.alphaRUEN
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_error_alpha') }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  id="input-group-photographer"
                  :label="$t('herbarium_form_page_input_photographer_label')"
                  label-for="photographer"
                  class="text-left"
                >
                  <b-form-input
                    id="photographer"
                    v-model="$v.form.photographer.$model"
                    :state="validateState('photographer')"
                    type="text"
                  />
                  <b-form-text
                    v-if="
                      $v.form.photographer.$dirty &&
                        !$v.form.photographer.maxLength
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.photographer.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="
                      $v.form.photographer.$dirty &&
                        !$v.form.photographer.alphaRUEN
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_error_alpha') }}
                  </b-form-text>
                </b-form-group>
                <b-form-group>
                  <TagSelect :customchange="watchTags" :values="form.tags" />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="text-center text-md-right">
            <b-button class="warning" @click="showModal">
              {{ $t('herbarium_form_page_btn_cancel') }}
            </b-button>
            <b-button type="submit" :disabled="isLoading" class="success">
              <b-spinner v-if="isLoading" show small type="grow" />
              {{
                isEdit
                  ? $t('herbarium_form_page_btn_edit')
                  : $t('herbarium_form_page_btn_submit')
              }}
            </b-button>
          </div>
        </b-form>
      </div>

      <div v-if="getErrorHerbarium" class="w-50 mx-auto mt-2">
        <b-alert show variant="danger">
          {{ $t(getErrorHerbarium) }}
        </b-alert>
      </div>
    </div>
    <Modal
      :confirmation="
        () => {
          this.pageLeaveConfirm = true
          this.$router
            .push({ name: isEdit ? 'AllHerbarium' : 'userHerbariumsImages' })
            .catch(() => {})
        }
      "
      :title="$t('component_modal_herbarium_cancel')"
      :id-modal="'modalCancel'"
    />
    <Modal
      :confirmation="this.handleModalConfirm"
      :title="$t('component_modal_herbarium_confirm')"
      :id-modal="'modalConfirm'"
    />
    <Modal
      :title="$t('herbarium_form_page_input_gps_map_label')"
      :id-modal="'modalMap'"
      :size="'xl'"
    >
      <template #modalBody>
        <Map
          :coordinates="{
            longitude: form.longitude,
            latitude: form.latitude
          }"
          @setCoord="setLatLng"
        />
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { decimal, helpers, maxLength, required } from 'vuelidate/lib/validators'
import Modal from '@/components/Modal.vue'
import ZoomImage from '@/components/ZoomImage.vue'
import TagSelect from '@/components/TagSelect.vue'
import Map from '@/components/Map.vue'
import Multiselect from 'vue-multiselect'

const alphaRU = helpers.regex('alpha', /^[а-яА-ЯёЁ,-. ]*$/)
const alphaEN = helpers.regex('alpha', /^[a-zA-Z,-. ]*$/)
const alphaRUEN = helpers.regex('alpha', /^[a-zA-Zа-яА-Я,-. ]*$/)

let timer = null

export default {
  components: {
    TagSelect,
    ZoomImage,
    Modal,
    Map,
    Multiselect
  },
  props: {
    isEdit: { type: Boolean, default: () => false },
    header: { type: Boolean, default: () => true }
  },
  data() {
    return {
      proId: this.$route.params.id,
      // classificationData: [],
      // familyData: [],
      // // genusData: [],
      family: null,
      genus: null,
      subSpecies: null,
      holotypeId: null,
      form: {
        gps: '',
        species: '',
        ruName: '',
        enName: '',
        image: '',
        collectedDate: '',
        area: '',
        zone: '',
        ridge: '',
        habitation: '',
        ground: '',
        phenology: '',
        elevation: '',
        naturalBoundary: '',
        specimentCollector: '',
        specimentIdentifier: '',
        photographer: '',
        colourOfFlower: '',
        catalogueNumber: '',
        latitude: 0,
        longitude: 0,
        tags: []
      },
      url: null,
      pageLeaveConfirm: false,
      pageToGoTo: ''
    }
  },
  validations: {
    form: {
      ruName: { required, maxLength: maxLength(50), alphaRU },
      enName: { required, maxLength: maxLength(50), alphaEN },
      collectedDate: {
        required,
        range: value => value < moment().format('YYYY-MM-DD')
      },
      area: { maxLength: maxLength(50), alphaRUEN },
      zone: { maxLength: maxLength(50), alphaRUEN },
      ridge: { maxLength: maxLength(50), alphaRUEN },
      habitation: { maxLength: maxLength(50), alphaRUEN },
      ground: { maxLength: maxLength(50), alphaRUEN },
      phenology: { maxLength: maxLength(50), alphaRUEN },
      elevation: { maxLength: maxLength(50), decimal },
      naturalBoundary: { maxLength: maxLength(50), alphaRUEN },
      specimentCollector: { maxLength: maxLength(50), alphaRUEN },
      specimentIdentifier: { maxLength: maxLength(50), alphaRUEN },
      photographer: { maxLength: maxLength(50), alphaRUEN },
      colourOfFlower: { maxLength: maxLength(50), alphaRUEN },
      catalogueNumber: { maxLength: maxLength(50) }
    }
  },
  computed: {
    ...mapGetters('herbarium', ['getItem', 'isLoading', 'getErrorHerbarium']),
    ...mapGetters('plants', ['getData', 'getFilteredData']),
    ...mapGetters('classification', ['classification', 'genusOfFamily']),
    familyData() {
      return this.classification
    },
    genusData() {
      return this.genusOfFamily
    },
    classificationData() {
      return this.getFilteredData.map(el => {
        return {
          id: el.id,
          name: el.laName,
          category: el.category,
          holotypeId: el.holotypeId,
          subSpecies: el.subSpecies
        }
      })
    },
    isFormEmpty() {
      let form = Object.entries(this.form)
      let result = form.every(elem => elem[1] == false)
      return result
    }
  },
  watch: {
    family(newVal) {
      if (!this.isEdit && this.genus && this.genus.id) this.genus = {}

      if (this.family && this.family.id) {
        this.getGenusOf(newVal)
      }
    },
    genus(newVal) {
      if (!this.isEdit && this.form.species && this.form.species.id)
        this.form.species = ''
      if (this.genus && this.genus.id) {
        this.getNewClassification(newVal)
      }
    }
  },

  async mounted() {
    await this.getClassification(`/classification`)
    this.clearErrorAction()
    if (this.proId) await this.fetchItem(this.proId)
    if (this.isEdit) {
      if (this.getItem.plant.category) {
        this.genus = this.getItem.plant.category
      }

      if (this.getItem.plant.category && this.getItem.plant.category.category) {
        this.family = this.getItem.plant.category.category
      }

      this.form = {
        ...this.getItem,
        gps: this.getItem.latitude + ', ' + this.getItem.longitude,
        species: {
          id: this.getItem.plant.id,
          name: this.getItem.plant.laName,
          category: this.getItem.plant.category,
          holotypeId: this.getItem.plant.holotypeId,
          subSpecies: this.getItem.plant.subSpecies
        }
      }

      if (this.getItem.plant && this.getItem.plant.subSpecies)
        this.subSpecies = this.getItem.plant.subSpecies

      if (this.getItem.plant && this.getItem.plant.holotypeId)
        this.holotypeId = this.getItem.plant.holotypeId
    }
    this.url = this.getItem.image
  },
  async beforeRouteLeave(to, from, next) {
    // eslint-disable-next-line
    if(this.pageLeaveConfirm) {
      next()
      return
    }
    if (this.isFormEmpty) {
      next()
      return
    }
    this.pageToGoTo = to
    await this.$root.$emit('bv::show::modal', 'modalConfirm')
    if (this.pageLeaveConfirm) {
      next()
    }
    next(false)
  },
  destroyed() {
    if (!this.isEdit) {
      this.$store.commit('herbarium/clearStateHerbarium')
    }
  },
  methods: {
    ...mapActions('herbarium', ['fetchItem', 'clearErrorAction']),
    ...mapActions('plants', ['fetchData', 'fetchDataFilter']),
    ...mapActions('classification', ['getClassification', 'getGenus']),
    showModal() {
      this.$root.$emit('bv::show::modal', 'modalCancel')
    },
    handleModalConfirm() {
      this.pageLeaveConfirm = true
      this.$router.push({ name: this.pageToGoTo.name }).catch(() => {})
    },
    changeItemToDB() {
      this.pageLeaveConfirm = true
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.form.collectedDate = new Date(this.form.collectedDate).toISOString()
      this.form.plantId = this.form.species.id
      delete this.form.species
      let formData = new FormData()
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key])
      })
      const dataForGhange = { id: this.proId, form: formData }
      this.$store
        .dispatch('herbarium/changeItemToDB', dataForGhange)
        .then(() => {
          this.$router
            .push({
              name: this.isEdit ? 'HerbariumGrid' : 'userHerbariumsImages'
            })
            .catch(() => {})
        })
    },
    addItemToDB() {
      this.pageLeaveConfirm = true
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      let formData = new FormData()
      this.form.collectedDate = new Date(this.form.collectedDate).toISOString()
      this.form.plantId = this.form.species.id
      delete this.form.species
      delete this.form.gps
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key])
      })
      this.$store.dispatch('herbarium/addItemToDB', formData).then(() => {
        this.$router
          .push({
            name: 'HerbariumGrid'
          })
          .catch(() => {})
      })
    },
    loadFile(event) {
      this.form.image = event.target.files[0]
      this.url = URL.createObjectURL(event.target.files[0])
    },
    watchTags(tags) {
      this.form.tags = tags.map(el => el.name)
    },
    getGenusOf: async function(newVal) {
      await this.getGenus(`/classification/genus?&family=${newVal.id}`)
    },
    getNewClassification: async function(newVal) {
      await this.fetchDataFilter(`/plants/filter?&genus=${newVal.id}`)
    },
    searchFamily: function(item) {
      if (timer) {
        clearTimeout(timer)
      }
      if (item.length >= 1) {
        timer = setTimeout(async () => {
          await this.getClassification(`/classification?&search=${item}`)
        }, 1000)
      }
    },
    searchGenus: function(item) {
      if (timer) {
        clearTimeout(timer)
      }
      if (item.length >= 1) {
        timer = setTimeout(async () => {
          if (this.family.id) {
            await this.getGenus(
              `/classification/genus?&family=${this.family.id}&search=${item}`
            )
          }
        }, 1000)
      }
    },
    search: function(item) {
      if (timer) {
        clearTimeout(timer)
      }
      if (item.length >= 1) {
        timer = setTimeout(async () => {
          if (this.genus.id) {
            await this.fetchDataFilter(
              `/plants/filter?&genus=${this.genus.id}&search=${item}`
            )
          }
        }, 1000)
      }
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.form[name]
      if (!$model.length && !$invalid) return null
      return $dirty ? !$error : null
    },
    showMap() {
      this.$root.$emit('bv::show::modal', 'modalMap')
    },
    setLatLng({ lat, lng }) {
      this.form.longitude = lng
      this.form.latitude = lat
      this.form.gps = `${lat}, ${lng}`
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .herbForm {
  padding: 1.5rem 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
  border-radius: 4px;
  .multiselect__single {
    background-color: #f7f7f7 !important;
  }
  .custom-file-label {
    background-color: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    outline: none !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input-group-append {
    background: #f7f7f7;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    @media (max-width: 426px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    .gpsicon {
      cursor: pointer;
    }
  }
  // .form-control.is-valid {
  //   padding: 0.375rem 0.75rem;
  // }
  .warning {
    background-color: #ffffff;
    color: #4f4f4f;
  }
  h5 {
    font-size: 1.313rem;
  }
  h6 {
    font-size: 1.125rem;
  }
  input,
  textarea,
  select {
    background-color: #f7f7f7;
    border: none;
    outline: none;
  }
  label {
    margin-bottom: 0;
  }
  .b-form-datepicker {
    background-color: #f7f7f7;
    border: none;
    outline: none;
  }
  .form-button {
    background-color: #42b983;
  }
  .form-button:hover {
    background-color: #2c3e50;
  }
}
</style>
