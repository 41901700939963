import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosApi from './axiosApi'
import './plugins/bootstrap'
import './plugins/validate'
import { i18n } from './plugins/i18n'
import 'leaflet/dist/leaflet.css'
import FlagIcon from 'vue-flag-icon'
import './plugins/zoom'
import './assets/style/custom.scss'

Vue.prototype.$http = axiosApi

Vue.config.productionTip = false
Vue.use(FlagIcon)

axiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axiosApi.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('token')
          router.push('/').catch(() => {})
          break
      }
      return Promise.reject(error.response)
    }
  }
)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
