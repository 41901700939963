import axios from '../axiosApi'

const iniitalState = {
  family: []
}

export default {
  state: { ...iniitalState },
  mutations: {
    setDataFamily(state, data) {
      state.family = data
    }
  },
  actions: {
    requesDataFamily({ commit, dispatch }, name) {
      axios(`/classification?search=${name}`)
        .then(res => res.data.map(el => ({ id: el.id, name: el.laName })))
        .then(res => commit('setDataFamily', res))
        .catch(({ data }) =>
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        )
    }
  },
  getters: {
    getFamily(state) {
      return state.family
    }
  }
}
