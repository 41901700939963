<template>
  <b-modal
    :id="idModal"
    :hide-footer="hideFooter"
    :size="size"
    :centered="centered"
  >
    <template v-slot:modal-header>
      <h6 class="my-0">{{ title }}</h6>
      <span>
        <img
          src="../assets/icon/icon-dismiss.svg"
          alt="dismiss"
          style="color : #212B36; vertical-align: baseline; cursor : pointer;"
          @click="hideModal"
        />
      </span>
    </template>
    <slot name="modalBody">
      <div class="HerbariumItemPageModalBtn">
        <div>
          <b-button class="mr-3 ml-auto success" @click="hideModal">
            {{ $t('component_modal_delete_item_btn_no') }}
          </b-button>
          <b-button class="mr-3 ml-auto danger" @click="confirmationAction">
            {{ $t('component_modal_delete_item_btn_yes') }}
          </b-button>
        </div>
      </div>
    </slot>
  </b-modal>
</template>

<script>
export default {
  props: {
    confirmation: { type: Function, default: () => {} },
    title: { type: String, default: () => '' },
    idModal: { type: String, default: () => '' },
    size: { type: String, default: () => '' },
    hideFooter: {
      type: Boolean,
      default: () => true
    },
    centered: { type: Boolean, default: () => true }
  },
  methods: {
    confirmationAction() {
      this.confirmation()
      this.hideModal()
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', this.idModal, '#btnShow')
    }
  }
}
</script>

<style lang="scss" scoped>
.HerbariumItemPageModalBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
