export default {
  state: { message: '', type: 'success', show: false },
  mutations: {
    setOptions(state, { message, type }) {
      state.message = message
      state.type = type
      state.show = true
    },
    clearOptions(state) {
      state.show = false
      state.message = ''
    }
  },
  actions: {
    showToast({ commit }, options) {
      commit('setOptions', options)
    },
    hideToast({ commit }) {
      commit('clearOptions')
    }
  },
  getters: {
    dataToast(state) {
      return state
    }
  }
}
