import Vue from 'vue'
import Vuex from 'vuex'
import herbarium from './herbarium'
import auth from './auth'
import toast from './toast'
import plants from './plants'
import users from './users'
import classification from './classification'
import filterFamily from './filterFamily'
import filterGenus from './filterGenus'
import filterSpecies from './filterSpecies'
import navigation from './navigation'

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
    clearAllState({ commit }) {
      commit('clear_state_auth')
      commit('clearStateUsers')
      commit('herbarium/clearStateHerbarium')
      commit('plants/clearStatePlants')
    }
  },
  modules: {
    herbarium,
    auth,
    toast,
    plants,
    users,
    classification,
    filterFamily,
    filterGenus,
    filterSpecies,
    navigation
  }
})
