import axios from '../axiosApi'

const initialState = {
  classification: [],
  family: [],
  genus: [],
  symbol: '',
  isLoading: false
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setClassification(state, data) {
      state.classification = data
    },
    setFamily(state, data) {
      state.family = data
    },
    setGenus(state, data) {
      state.genus = data
    },
    clearClassifications(state) {
      state.classification = []
    },
    setLoading(state) {
      state.isLoading = !state.isLoading
    },
    setSymbol(state, data) {
      state.symbol = data
    }
  },
  actions: {
    getClassification({ commit, dispatch }, symbol = '/classification') {
      commit('setLoading')
      // commit('clearClassifications')
      // commit('setSymbol', symbol)
      axios
        .get(symbol)
        .then(({ data }) => {
          commit('setClassification', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('setLoading')
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        })
    },
    getFamily({ commit, dispatch }, symbol = '/classification/family') {
      commit('setFamily', [])
      commit('setLoading')
      axios
        .get(symbol)
        .then(({ data }) => {
          commit('setFamily', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('setLoading')
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        })
    },
    getGenus({ commit, dispatch }, symbol = '/classification/genus') {
      commit('setLoading')

      axios
        .get(symbol)
        .then(({ data }) => {
          commit('setGenus', data)
          commit('setLoading')
        })
        .catch(({ data }) => {
          commit('setLoading')
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        })
    },
    sendClassification({ dispatch }, data) {
      return axios
        .post('/classification', data)
        .then(({ data }) => data)
        .catch(({ data }) =>
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        )
    },
    deleteClassification({ dispatch }, id) {
      return axios
        .delete(`/classification/${id}`)
        .then(({ data }) => {
          dispatch(
            'showToast',
            { type: 'success', message: data.code },
            { root: true }
          )
          return data.code
        })
        .catch(({ data }) =>
          dispatch(
            'showToast',
            { type: 'warning', message: data.code },
            { root: true }
          )
        )
    },
    editClassification({ dispatch }, data) {
      return axios
        .put(`/classification/${data.id}`, data.edit)
        .then(({ data }) => data)
        .catch(({ data }) =>
          dispatch('showToast', { type: 'warning', message: data.code })
        )
    }
  },
  getters: {
    classification(state) {
      return state.classification
    },
    family(state) {
      return state.family
    },
    genusOfFamily(state) {
      return state.genus
    },
    symbol(state) {
      return state.symbol
    },
    loading(state) {
      return state.isLoading
    }
  }
}
