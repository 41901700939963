<template>
  <div v-click-outside="zoomReset" class="zoom-wrap">
    <VZoomer
      ref="zoomer"
      class="zoom"
      :zoomed.sync="zoomed"
      :double-click-to-zoom="false"
      :style="{ maxWidth: width, maxHeight: height }"
    >
      <img :src="imageUrl" />
    </VZoomer>
    <b-icon class="button" icon="search" @click="zoomZoom" />
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'ZoomImage',
  directives: {
    ClickOutside
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    width: { type: String },
    // eslint-disable-next-line vue/require-default-prop
    height: { type: String },
    // eslint-disable-next-line vue/require-default-prop
    imageUrl: { type: String }
  },
  data() {
    return {
      zoomed: false
    }
  },
  methods: {
    zoomZoom() {
      if (this.zoomed) {
        this.$refs.zoomer.reset()
        this.zoomed = false
      } else {
        this.$refs.zoomer.zoomIn()
      }
    },
    zoomReset() {
      if (this.zoomed) {
        this.$refs.zoomer.reset()
        this.zoomed = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom-wrap {
  position: relative;
  .zoom {
    border: solid 1px silver;
    img {
      /* object-fit: contain; */
      width: 100%;
      height: 100%;
    }
  }
  .button {
    position: absolute;
    bottom: 10px;
    color: #afaeae;
    right: 15px;
    cursor: pointer;
    font-size: 2rem;
  }

  .button:hover {
    font-size: 2.5rem;
  }
}
</style>
