const initialState = {
  perPage: 4,
  currentPage: 1
}

export default {
  state: { ...initialState },
  mutations: {
    setPerPage(state, value) {
      state.perPage = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    }
  },
  actions: {
    changePerPage({ commit }, value) {
      commit('setPerPage', value)
    },
    changeCurrentPage({ commit }, value) {
      commit('setCurrentPage', value)
    }
  },
  getters: {
    getPerPage(state) {
      return state.perPage
    },
    getCurrentPage(state) {
      return state.currentPage
    }
  }
}
